import React from "react";
import imgThree from "../../assets/servicePage/gallery/3.png";
import imgFour from "../../assets/servicePage/gallery/4.png";
import imgFive from "../../assets/servicePage/gallery/5.png";
import imgSix from "../../assets/servicePage/gallery/6.png";

// Sample video imports
import videoOne from "../../assets/servicePage/gallery/video1.mp4";
import videoTwo from "../../assets/servicePage/gallery/video2.mp4";
import videoFour from "../../assets/servicePage/gallery/video4.mp4";
import videoFive from "../../assets/servicePage/gallery/video5.mp4";
import videoSix from "../../assets/servicePage/gallery/video6.mp4";
import videoEight from "../../assets/servicePage/gallery/video8.mp4";
import videoEleven from "../../assets/servicePage/gallery/video11.mp4";
import videoNine from "../../assets/servicePage/gallery/video9.mp4";

import "./ServiceBanner.scss";

const ServiceBanner = () => {
  const mediaItems = [
    { type: "image", src: imgThree, alt: "Gallery Image 3" },

    { type: "image", src: imgFour, alt: "Gallery Image 4" },
    { type: "image", src: imgFive, alt: "Gallery Image 5" },
    { type: "image", src: imgSix, alt: "Gallery Image 6" },
    // { type: "image", src: imgThree, alt: "Gallery Image 3" },

    { type: "video", src: videoOne, alt: "Gallery Video 1" },
    { type: "video", src: videoTwo, alt: "Gallery Video 2" },
    { type: "video", src: videoFour, alt: "Gallery Video 4" },
    { type: "video", src: videoFive, alt: "Gallery Video 5" },
    { type: "video", src: videoSix, alt: "Gallery Video 6" },
    { type: "video", src: videoEight, alt: "Gallery Video 8" },
    { type: "video", src: videoEleven, alt: "Gallery Video 11" },
    { type: "video", src: videoNine, alt: "Gallery Video 9" },
  ];

  return (
    <section className="products">
      <p>Some proof about our Products for you</p>
      <h1>
        We provide certified documentation and third-party verification to
        guarantee the authenticity and quality of our products.
      </h1>
      <div className="media-grid">
        {mediaItems.map((item, index) => (
          <div key={index} className="media-item">
            {item.type === "image" ? (
              <img src={item.src} alt={item.alt} className="media-image" />
            ) : (
              <video controls className="media-video">
                <source src={item.src} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
          </div>
        ))}
      </div>
    </section>
  );
};

export default ServiceBanner;
