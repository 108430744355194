import React from "react";
import { Link } from "react-router-dom";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import priorityImg from "../../assets/about/priority.png";
import "./Priority.scss";

const Priority = () => {
  return (
    <section
      className="priority-section emergency-section"
      data-aos="fade-up"
      data-aos-duration="2000"
    >
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-6">
            <div className="priority-img">
              <img
                src={priorityImg}
                alt="Emergency"
                style={{
                  width: "100%", // Makes the image responsive and takes full width of its container
                  height: "auto", // Maintains the aspect ratio of the image
                  maxHeight: "600px", // Optional: set a max height for the image to control its size
                  objectFit: "cover", // Ensures the image covers the container fully
                  borderRadius: "10px", // Optional: adds rounded corners to the image
                }}
              />
            </div>
          </div>
          <div className="col-lg-6 col-md-6">
            <div className="priority-text">
              <SectionTitle
                subTitle="OUR PHILOSOPHIES"
                title="Our clients are our priority"
                description="At Brand Mining Ltd, our philosophies are rooted in integrity, innovation, and sustainability. We are committed to ethical practices, delivering innovative solutions, and fostering sustainable growth. Our approach is guided by transparency, collaboration, and a relentless pursuit of excellence, ensuring value for our clients, communities, and the environment...."
              />

              <div className="theme-btn">
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Priority;
