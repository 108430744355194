import React from 'react';
import './Expert.scss';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import { BsFillCheckCircleFill } from "react-icons/bs";
import expertImg from '../../assets/expert.jpg';

const Expert = () => {
    return (
      <section
        className="expert-section"
        data-aos="fade-up"
        data-aos-duration="2000"
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6">
              <div className="expert-text">
                <SectionTitle
                  subTitle="EXPERTS IN PRECIOUS MINERALS"
                  title="Why Choose Us."
                  description="At Brand Mining Ltd, we offer premium minerals, including gold, sourced ethically and delivered with integrity. Trust us for quality, reliability, and exceptional service."
                />

                <ul>
                  <li>
                    <BsFillCheckCircleFill />
                    Unmatched Quality
                  </li>
                  <li>
                    <BsFillCheckCircleFill />
                    Expertise and Experience
                  </li>
                  <li>
                    <BsFillCheckCircleFill />
                    Global Reach
                  </li>
                  <li>
                    <BsFillCheckCircleFill />
                    Customer-Centric Approach
                  </li>
                  <li>
                    <BsFillCheckCircleFill />
                    Certified Authenticity
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="expert-img">
                <img src={expertImg} alt="expert" />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
};

export default Expert;