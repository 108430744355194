import React from "react";
import "./Navbar.scss";
import logo from "./../../assets/logo.png";
import { Link } from "react-router-dom";

const Navbar = () => {
  const whatsappNumber = "254108001128"; // Replace with your WhatsApp number

  const navbarItems = [
    { name: "Home", path: "/" },
    { name: "About Us", path: "/about" },
    { name: "Products", path: "/singleservice" },
    { name: "Contact Us", path: "/contact" },
  ];

  return (
    <div className="main-nav">
      <div className="container">
        <nav className="navbar navbar-expand-lg">
          <div className="container-fluid">
            {/* Logo */}
            <Link className="navbar-brand" to="/">
              <img src={logo} alt="logo" style={{ height: "40px" }} />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              {/* Navbar Links */}
              <ul className="navbar-nav m-auto mb-2 mb-lg-0">
                {navbarItems.map((navSingle, index) => (
                  <li className="nav-item" key={index}>
                    <Link className="nav-link" to={navSingle.path}>
                      {navSingle.name}
                    </Link>
                  </li>
                ))}
              </ul>

              {/* WhatsApp Button */}
              <a
                href={`https://wa.me/${whatsappNumber}?text=Hello, I would like to inquire about your services.`}
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-whatsapp"
              >
                Chat on WhatsApp
              </a>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;
