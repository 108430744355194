import React from "react";
import imgThree from "../../assets/servicePage/gallery/3.png";
import imgFour from "../../assets/servicePage/gallery/4.png";
import imgFive from "../../assets/servicePage/gallery/5.png";
import imgNine from "../../assets/servicePage/gallery/6.png";

// Sample video imports
import videoOne from "../../assets/servicePage/gallery/video1.mp4";
import videoTwo from "../../assets/servicePage/gallery/video2.mp4";
import videoFour from "../../assets/servicePage/gallery/video4.mp4";
import videoFive from "../../assets/servicePage/gallery/video5.mp4";
import videoSix from "../../assets/servicePage/gallery/video6.mp4";
import videoEight from "../../assets/servicePage/gallery/video8.mp4";
import videoEleven from "../../assets/servicePage/gallery/video11.mp4";
import videoNine from "../../assets/servicePage/gallery/video9.mp4";
import videoFifteen from "../../assets/servicePage/gallery/video15.mp4";
import videoFourteen from "../../assets/servicePage/gallery/video14.mp4";

import "./Products.scss";

const Products = () => {
  const mediaItems = [
    { type: "video", src: videoFifteen, alt: "Gallery Video 15" },
    { type: "video", src: videoFourteen, alt: "Gallery Video 14" },
    { type: "video", src: videoOne, alt: "Gallery Video 1" },
    { type: "video", src: videoTwo, alt: "Gallery Video 2" },
    { type: "video", src: videoFour, alt: "Gallery Video 4" },
    { type: "video", src: videoFive, alt: "Gallery Video 5" },
    { type: "video", src: videoSix, alt: "Gallery Video 6" },
    { type: "video", src: videoEight, alt: "Gallery Video 8" },
    { type: "video", src: videoEleven, alt: "Gallery Video 11" },
    { type: "video", src: videoNine, alt: "Gallery Video 9" },
  ];

  return (
    <section className="products">
      <div className="media-grid">
        {mediaItems.map((item, index) => (
          <div key={index} className="media-item">
            {item.type === "image" ? (
              <img src={item.src} alt={item.alt} className="media-image" />
            ) : (
              <video controls className="media-video">
                <source src={item.src} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
          </div>
        ))}
      </div>
    </section>
  );
};

export default Products;
