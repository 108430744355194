import React from 'react';
import logo from '../../assets/footer_logo.png';
import './Footer.scss';
import { FaFacebookF, FaInstagram, FaTwitter} from "react-icons/fa";
import { Link } from 'react-router-dom';
import call from '../../assets/footer/calling.png';
import time from '../../assets/footer/time.png';
import location from '../../assets/footer/location.png';

const Footer = () => {

    const footerMenu = [
        {
            'name' : 'About Us',
            'link' : '/'
        },
        {
            'name' : 'products',
            'link' : '/'
        },
        {
            'name' : 'gallery',
            'link' : '/'
        },
        {
            'name' : 'Blogs',
            'link' : '/'
        },
        {
            'name' : 'FAQs',
            'link' : '/'
        }
    ];

    const footerContacts = [
      {
        title: "Phone Number",
        info: " +254 108 001128 / 0208893999",
        icon: call,
      },

      {
        title: "Brand Mining Ltd",
        info: "Kitisuru",
        icon: location,
      },
    ];

    return (
      <footer className="pt-10 pb-30">
        <div className="containersd">
          <div className="row">
            <div className="col-lg-6 col-md-5">
              <div className="footer-logo">
                {/* <img src={logo} alt="logo" />  */}
              </div>
              <p>
                Brand Mining is a top-rated provider of high-quality minerals, renowned for our reliability and customer satisfaction.
              </p>

              <div className="social-logo">
                <p>Follow us on</p>
                <ul>
                  <li>
                    <a href="/">
                      <FaFacebookF />
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      <FaTwitter />
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      <FaInstagram />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-2">
              <div className="footer-link">
                <p>Quick Links</p>
                <ul>
                  {footerMenu.map((singleMenu) => (
                    <li>
                      <Link to="/">{singleMenu.name}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-md-5">
              <div className="footer-contact">
                <p>Contact & Information</p>

                {footerContacts.map((footerContact) => {
                  return (
                    <div className="contact-list">
                      <div className="contact-icon">
                        <img src={footerContact.icon} alt="call" />
                      </div>
                      <div className="contact-text">
                        <p>{footerContact.title}</p>
                        <h5>{footerContact.info}</h5>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="copyright-area">
            <div className="copy-text">
              <p>
                &copy; Brand Mining
                Ltd. All Right Reserved
              </p>
            </div>
            <div className="copy-links">
              <ul>
                <li>{/* <Link to="/">Terms of Use</Link> */}</li>
                <li>{/* <Link to="/">Privacy Policy</Link> */}</li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    );
};

export default Footer;