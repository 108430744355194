import React from "react";
import { ShieldCheck, Award, LifeBuoy } from "lucide-react";
import "./Testimonial.scss"; // Import SCSS file

const Testimonial = () => {
  const values = [
    {
      title: "Standard",
      description:
        "Standing tall on the pillars of innovation and precision, we proudly set the Standard. With a relentless pursuit of excellence, we redefine industry norms, ensuring that our product not only meets but surpasses the benchmarks of quality and sophistication.",
      icon: <Award className="icon" />,
    },
    {
      title: "Quality",
      description:
        "At our essence, we stand for Quality – a commitment to excellence that runs deep in every facet of our product. Immerse yourself in the assurance that precision, craftsmanship, and uncompromising standards define our dedication to delivering the finest.",
      icon: <ShieldCheck className="icon" />,
    },
    {
      title: "Support",
      description:
        "What sets us apart is the unwavering Support we offer. Beyond transactions, we provide a guiding hand, expertise, and genuine care throughout your journey with us. We have extended our support to the community by establishing an orphanage.",
      icon: <LifeBuoy className="icon" />,
    },
  ];

  return (
    <section className="what-we-stand-for">
      <div className="container">
        <h2 className="title">What We Stand For</h2>
        <div className="grid">
          {values.map((value, index) => (
            <div key={index} className="grid-item">
              <div className="icon-container">{value.icon}</div>
              <h3 className="grid-title">{value.title}</h3>
              <p className="grid-description">{value.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Testimonial;
