import React from "react";
import { Link } from "react-router-dom";
import "./AboutBanner.scss";
import bannerOne from "../../assets/about/banner/n.jpg";
// import bannerTwo from '../../assets/about/banner/banner_2.png'
import pattern from "../../assets/banner/pattern.png";

const AboutBanner = () => {
  return (
    <section
      className="about-section"
      data-aos="fade-up"
      data-aos-duration="2000"
    >
      <div className="d-table">
        <div className="d-table-cell">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-5">
                <div className="about-banner-text">
                  <h2>ABOUT US</h2>
                  <p>
                    Brand Mining Ltd is a trusted leader in the mineral
                    industry, specializing in the sale of premium minerals,
                    including gold. With a commitment to ethical sourcing and
                    quality assurance, we pride ourselves on delivering
                    exceptional value to our customers. Our experienced team is
                    dedicated to upholding the highest standards of service,
                    ensuring a reliable and transparent buying experience.
                  </p>

                  <div className="theme-btn">
                    <Link to="/">Contact Us</Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="about-banner-img">
                  <img
                    src={bannerOne}
                    alt="about banner"
                    style={{
                      width: "100%", // makes the image responsive
                      height: "auto", // keeps the aspect ratio
                      objectFit: "cover", // ensures the image covers the container fully
                      borderRadius: "10px", // optional: adds rounded corners
                      boxShadow: "0 10px 20px rgba(0, 0, 0, 0.1)", // optional: adds shadow to image
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutBanner;
