import React from "react";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import Navbar from "../../components/Navbar/Navbar";
import "./Contactus.scss";
import Footer from "../../sections/Footer/Footer";

const Contactus = () => {
  const containerStyle = {
    padding: "50px 20px",
    backgroundColor: "#f9f9f9",
    textAlign: "center",
  };

  const formStyle = {
    maxWidth: "600px",
    margin: "0 auto",
    backgroundColor: "#fff",
    padding: "30px",
    borderRadius: "8px",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
  };

  const inputStyle = {
    width: "100%",
    padding: "12px",
    margin: "10px 0",
    border: "1px solid #ddd",
    borderRadius: "4px",
    fontSize: "16px",
  };

  const textareaStyle = {
    ...inputStyle,
    height: "100px",
    resize: "none",
  };

  const buttonStyle = {
    padding: "12px 20px",
    backgroundColor: "#007BFF",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    fontSize: "16px",
  };

  const headingStyle = {
    marginBottom: "20px",
    fontSize: "24px",
    fontWeight: "bold",
    color: "#333",
  };

  const infoStyle = {
    marginTop: "30px",
    color: "#555",
  };

  return (
    <>
      {/* Include Navbar */}
      <Navbar />

      {/* Main Contact Section */}
      <section style={containerStyle}>
        <h2 style={headingStyle}>Contact Us</h2>
        <form style={formStyle}>
          <input
            type="text"
            placeholder="Your Name"
            style={inputStyle}
            required
          />
          <input
            type="email"
            placeholder="Your Email"
            style={inputStyle}
            required
          />
          <input
            type="text"
            placeholder="Subject"
            style={inputStyle}
            required
          />
          <textarea
            placeholder="Your Message"
            style={textareaStyle}
            required
          ></textarea>
          <button type="submit" style={buttonStyle}>
            Send Message
          </button>
        </form>
        <div style={infoStyle}>
          <p>
            <strong>Email:</strong> info@brandmininglimited.com
          </p>
          <p>
            <strong>Phone:</strong> +254 108 001128 / 0208893999
          </p>
          <p>
            <strong>Address:</strong> Kitisuru, Nairobi, Kenya
          </p>
        </div>
      </section>

      {/* Include Footer */}
      <Footer />
    </>
  );
};

export default Contactus;
